import React from "react";
import AOS from "aos";
AOS.init();

const hero = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>My Resume</h2>
          <div className="space-border"></div>
        </div>
        <p className="text-center">
          <a
            href="https://www.linkedin.com/in/banuakman/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download My Resume
          </a>
        </p>
      </div>
      <div className="row gh-5">
        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Experiences</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Jan 2019 - present</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Lead User Experience Designer & Web Developer
                  </span>
                  <span className="d_company">Breative Design Lab</span>
                  <li>
                    • Collaborated with startups and non-profits to craft
                    digital experiences and systems, focusing on enhancing
                    community and team productivity.
                  </li>
                  <li>
                    • Conducted comprehensive user research through interviews
                    and usability testing to guide intuitive UX/UI design across
                    various projects.
                  </li>
                  <li>
                    • Utilized AI tools in system design to streamline team
                    workflows, enhancing efficiency and engagement based on user
                    feedback analysis.
                  </li>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Dec 2018 - Present</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Event & Digital Outreach Strategist
                  </span>
                  <span className="d_company">
                    Bridge Engineering Association
                  </span>
                  <li>
                    • Spearhead the conceptualization and production of
                    BridgeTalks, engaging global bridge engineers through a
                    curated video series
                  </li>
                  <li>
                    • Oversee the design, development, and continuous
                    improvement of the association's website.
                  </li>
                  <li>
                    • Drive the end-to-end planning, marketing, and execution of
                    key conference activities, ensuring a seamless experience
                    for attendees.
                  </li>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Feb 2017 - Aug 2019</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Strategic Communications & Business Development
                  </span>
                  <span className="d_company">
                    Bridgewiz International Engineering and Consulting
                  </span>
                  <li>
                    • Developed and executed communication strategy and plan for
                    launching BridgeWiz’s online courses and calculators for the
                    international bridge engineering community.
                  </li>
                  <li>
                    • Designed creative and compelling digital content, the
                    company logo, and the website.
                  </li>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Aug 2011 - Sep 2017</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Project Manager</span>
                  <span className="d_company">
                    Asterya Events & Destination Management
                  </span>
                  <li>
                    • Led project management for over 150 international events,
                    handling budgets up to $2,000,000. Expertly coordinated with
                    diverse teams across concept development, graphic design,
                    and audio-visual production, catering to a wide range of
                    subjects and global audiences. Applied strategic market
                    analysis to inform client decision-making and ensure the
                    success of each event from inception to execution.
                  </li>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Education</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">
                  August 2021 - December 2022
                </h3>
                <p className="d_timeline-text">
                  <span className="d_title">Masters in UX Design</span>
                  <span className="d_company">
                    Maryland Institue College of Art
                  </span>
                  Graduated with a stellar 4.0 GPA, mastering a comprehensive
                  curriculum that spanned from foundational UX research and
                  design to advanced prototyping, human-centered design, and UX
                  product management. Distinguished as a finalist in the MICA
                  UP/Start Venture Competition, showcasing exceptional
                  innovation and application of UX principles in a competitive,
                  real-world setting.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">June - July 2021</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Java React Native Bootcamp</span>
                  <span className="d_company">
                    Southern Methodist University
                  </span>
                  A 6-week intensive program focused on Java Language,
                  Object-Oriented Programming, Data Structures, Spring Boot,
                  DevOps, Testing, Spring Relational Data, Microservices, Spring
                  Security, OAuth, and Deployment with AWS and React Native.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">January - March 2021</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Full-Stack Web Development</span>
                  <span className="d_company">Columbia University</span>A
                  12-week intensive program focused on gaining technical
                  programming skills in HTML5, CSS3, Javascript, JQuery,
                  Bootstrap, Firebase, Node Js, MySQL, MongoDB, Express,
                  Handelbars.js & ReactJS.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Other Certificates</h3>
                <p className="d_timeline-text">
                  <span className="d_title">January 2024</span>• Accessibility:
                  How to Design for All
                  <span className="d_title">December 2023</span>• Google Project
                  Management Certificate
                  <span className="d_title">November 2023</span>• AI-Powered UX
                  Design
                  <span className="d_title">March 2021</span>• Code in Place -
                  Introductory Python Programming Course
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="spacer-double"></div>
      </div>
    </div>
  );
};

export default hero;
