import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NavbarPortfolio = function () {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-portfolio transition ${
        scrolled ? "scrolled" : ""
      }`}
    >
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src="./img/logo.png" className="img-fluid imgbanu" alt="logo" />
        </Link>

        {/* Desktop menu items, hidden on small screens */}
        <div className={`dekstopmenu ${showMenu ? "hide" : ""}`}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link transition">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/?scrollTo=gallery" className="nav-link transition">
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/?scrollTo=about" className="nav-link transition">
                About me
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/?scrollTo=toolbox" className="nav-link transition">
                My tool box
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/?scrollTo=resume" className="nav-link transition">
                My resume
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/?scrollTo=contact" className="nav-link transition">
                Contact me
              </Link>
            </li>
          </ul>
        </div>

        <button
          className="burgermenu"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>

        {/* Mobile menu */}
        {showMenu && (
          <div className="mobilemenu">
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
              <li className="nav-item">
                <Link to="/" className="nav-link transition">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/?scrollTo=gallery" className="nav-link transition">
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/?scrollTo=about" className="nav-link transition">
                  About me
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/?scrollTo=toolbox" className="nav-link transition">
                  My tool box
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/?scrollTo=resume" className="nav-link transition">
                  My resume
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/?scrollTo=contact" className="nav-link transition">
                  Contact me
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavbarPortfolio;
