import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

const Navbar = function () {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const flytext = document.getElementById("fly");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;

    const handleScroll = () => {
      setShowMenu(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("scrolled");
        totop.classList.add("show");
        flytext.classList.add("hide");
        setScrolled(true);
      } else {
        header.classList.remove("scrolled");
        totop.classList.remove("show");
        flytext.classList.remove("hide");
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar transition ${scrolled ? "scrolled" : ""}`}>
      <div className="container">
        <Link className="navbar-brand" activeClass="active" spy to="hero-area">
          <img src="./img/logo.png" className="img-fluid imgbanu" alt="logo" />
        </Link>
        {/* Desktop menu Here */}
        <div className="dekstopmenu">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link transition"
                activeClass="active"
                spy
                to="gallery"
              >
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link transition"
                activeClass="active"
                spy
                to="about"
              >
                About me
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link transition"
                activeClass="active"
                spy
                to="toolbox"
              >
                My tool box
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link transition"
                activeClass="active"
                spy
                to="resume"
              >
                My resume
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link transition"
                activeClass="active"
                spy
                to="contact"
              >
                Contact me
              </Link>
            </li>
          </ul>
        </div>
        {/* Desktop menu Here */}

        {/* mobile menu here */}
        {showMenu && (
          <div className="mobilemenu">
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  smooth
                  spy
                  to="hero-area"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  smooth
                  spy
                  to="about"
                >
                  About me
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link transition"
                  smooth
                  activeClass="active"
                  spy
                  to="whatido"
                >
                  My Tools
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link transition"
                  smooth
                  activeClass="active"
                  spy
                  to="gallery"
                >
                  Portofolio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link transition"
                  smooth
                  activeClass="active"
                  spy
                  to="resume"
                >
                  My resume
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link transition"
                  smooth
                  activeClass="active"
                  spy
                  to="contact"
                >
                  Contact Me
                </Link>
              </li>
            </ul>
          </div>
        )}
        <button
          className="burgermenu"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        {/* mobile menu here */}
      </div>
    </nav>
  );
};

export default Navbar;
