import React from "react";
import { Link } from "react-scroll";
import Typed from "react-typed";
import AOS from "aos";

AOS.init({
  offset: 200,
  duration: 800,
  easing: "ease-in-out-sine",
  delay: 200,
  mirror: true,
});

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="spacer-20"></div>
            <div className="h1_big mb-0">
              Hi, I'm <span className="color">Banu Akman</span>
            </div>
            <div
              className="h1_big"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              <Typed
                strings={[
                  "a Product Designer. ",
                  "an Ethical AI Enthusiast",
                  "an Insightful Researcher.",
                  "a Passionate Learner.",
                ]}
                typeSpeed={60}
                backSpeed={50}
                loop
              />
            </div>
            <div className="spacer-20"></div>
            <h4
              className="list_location"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              I design with empathy and curiosity
            </h4>
            <h5
              className="list_location"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              to transform complex problems into human-centered solutions
            </h5>
            <button className="btn-main mt-3">
              <Link className="nav-link transition" spy to="gallery">
                My Portfolio
              </Link>
            </button>
            <button className="btn-secondary mt-3">
              <Link className="nav-link transition" spy to="resume">
                My Resume
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
