import React from "react";
import AOS from "aos";
AOS.init();

const toolbox = () => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 text-center"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <h2>My Tool Box</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="spacer-single"></div>
      <div className="row">
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="icon_genius id-color-2"></i>
              <div className="text">
                <h3>Technical Skills</h3>
                <p>
                  My technical repertoire encompasses a range of coding and
                  design skills essential for user-centric digital solutions.
                  These include:
                </p>
                <p>
                  <b>Design and User Experience: </b>UX & UI Design, Usability
                  Testing, Persona Development, User Flows, Prototyping,
                  Information Architecture
                </p>
                <p>
                  <b>Front-End Development: </b>HTML, CSS, Javascript, jQuery,
                  CSS Frameworks (Bootstrap, Tailwind CSS, Material), WordPress
                </p>
                <p>
                  <b>Back-End Development: </b>Java, PHP, Node.JS, Express, Java
                  Spring
                </p>
                <p>
                  {" "}
                  <b>Database Management: </b>MySQL, MongoDB, Mongoose,
                  Sequelize
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="400"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="icon_tool id-color-2"></i>
              <div className="text">
                <h3>Mastered Tools</h3>
                <p>
                  I pride myself on being an early adopter of technologies, yet
                  I firmly believe that it's not just the tools but the skills
                  and approach towards utilizing them that truly matter.
                </p>
                <p>
                  <b>Design and Prototyping: </b>Figma, Adobe XD, Illustrator,
                  Photoshop, InDesign, After Effects, Premier Pro
                </p>
                <p>
                  <b>Project Management and Collaboration: </b>Jira, Trello,
                  Notion, Slack, Zappier
                </p>
                <p>
                  <b>Development and Version Control: </b>VSCode, IntelliJ, Git,
                  GitHub
                </p>
                <p>
                  <b>Analytics, AI, and Presentation Tools: </b>Google Analytic,
                  Google Workspace, Microsoft 365, Prezi, ChatGPT, Dall-E,
                  Midjourney
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="icon_book id-color-2"></i>
              <div className="text">
                <h3>Inspiration Library</h3>
                <p>
                  My Inspiration Library is a curated collection reflecting the
                  diverse influences that shape my approach to UX design and
                  problem-solving.
                </p>
                <p>
                  It encompasses a range of subjects that contribute to my
                  professional and personal development, forming the
                  intellectual foundation of my practice.
                </p>
                <p>
                  This collection guides my approach to UX design, team
                  collaboration, and creative problem-solving.
                </p>
                <p>Explore my influential readings at</p>
                <p>
                  <a
                    href="https://literal.club/banushka"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    literal.club/banushka
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default toolbox;
