import React from "react";

const footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <span className="copy">
              Copyright &copy; 2021-2024 Banu Akman. Site made by Banu using
              React.
            </span>
          </div>
          <div className="col-md-6">
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/in/banuakman/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
              <a
                href="hhttps://github.com/banuakman"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-github"></i>
              </a>

              <a
                href="https://www.instagram.com/banushka/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
