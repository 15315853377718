import React from "react";
import { Helmet } from "react-helmet";
import NavbarPortfolio from "../layout/NavbarPortfolio";
import { GlobalStyles } from "./style/banustyle";

const Portfolio = function () {
  return (
    <div className="home">
      <GlobalStyles />

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:type" content="website" />
        <meta name="description" content="UPRAVEN - " />
        <meta property="og:title" content="UPRAVEN UX STUDY" />
        <meta
          property="og:description"
          content="A Mindful Growth Platform for Women Entrepreneurs"
        />
        <meta
          property="og:image"
          content="https://www.banuakman.com/img/banuassets/prev_upraven.jpg"
        />
        <meta property="og:url" content="https://www.banuakman.com/upraven" />
        <title>UPRAVEN - UX Study by Banu Akman</title>
      </Helmet>

      <header id="header-wrap">
        <NavbarPortfolio />
      </header>

      <main className="portfolio-content">
        <div className="container-fluid p-0">
          <picture>
            <source
              srcSet="./img/banuassets/UPRAVEN_HERO_mobile.jpg"
              media="(max-width: 768px)"
            />
            <img
              src="./img/banuassets/UPRAVEN_HERO.jpg"
              alt="galleryimage"
              className="img-fluid"
            />
          </picture>
        </div>
        <div className="container">
          <div className="row gx-5">
            <div className="col-md-7">
              <h2>PROBLEM</h2>
              <p>
                As a serial business founder and mentor, I've seen the unique
                challenges small business owners face, particularly those from
                underrepresented groups. My work with Entrepreneurship
                Assistance Centers in NY and various small businesses has shown
                me their vulnerabilities, especially during the COVID-19
                pandemic. These experiences have deepened my commitment to
                supporting these communities.
              </p>
              <p>
                During my MICA UX Design Masters program, I seized the
                opportunity to create a meaningful solution for women
                entrepreneurs as my Capstone Project. Over ten weeks, I
                navigated from uncertainty to clarity, leveraging my firsthand
                experience to empower this underserved group.
              </p>

              <p>
                Women in entrepreneurship often lead ventures in sectors with
                limited growth potential, facing a business world less dynamic
                and expansive than their male counterparts. This economic
                disparity is compounded by a profound "confidence gap",
                ingrained by societal, psychological, and educational biases.
                Research shows that{" "}
                <b>
                  confidence builds through acquiring and applying new skills in
                  real-world scenarios, solidifying knowledge and enhancing
                  self-assurance.
                </b>{" "}
                Empowering women with entrepreneurial knowledge is just the
                start of a long journey to confidence for business success.
              </p>
              <h4 className="highlighted">
                How might we develop a tailored digital experience that equips
                women entrepreneurs with essential skills and enhances their
                ability to retain and apply this knowledge effectively in their
                businesses?{" "}
              </h4>
            </div>
            <div className="col-md-5">
              <h2>SOLUTION</h2>
              <p>
                My solution is an effortless learning experience for busy women
                entrepreneurs and seamlessly creating deep thinking time to
                reveal their blind spots to opportunities - short quests for new
                skills that can be reached anywhere, anytime.
              </p>
              <h4 className="blockborder">
                UPRAVEN is designed as a mindful learning platform that creates
                a place for women business owners to work ON their business
                rather than in it.
              </h4>
              <p>
                <b>Primary Users: Women Solo Entrepreneurs</b> <br />
                Ninety percent of women and minority small business owners
                operate without employees, often becoming overwhelmed by daily
                tasks. Our core user base consists of solo women entrepreneurs
                at various stages, from ideation to mature operations. UPRAVEN
                provides them with essential skills, knowledge, and a support
                network, helping them navigate the complexities of
                entrepreneurship. This focus supports economic empowerment and
                gender equality.
              </p>
              <p>
                <b>Secondary Users: Collaborators in Entrepreneurial Success</b>
                <br />
                UPRAVEN is enhanced by the expertise of business coaches,
                educators, and government agencies, who support our primary
                users through mentorship, education, and policy-making. This
                collaboration creates a synergistic ecosystem, enabling small
                business owners to thrive.
              </p>
            </div>
          </div>
          <div className="spacer-30"></div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-10">
              <video className="pop" controls>
                <source
                  src="https://banuassets.s3.us-east-2.amazonaws.com/upraven_video.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="spacer-50"></div>
          </div>

          <div className="row gx-5">
            <div className="col-lg-12">
              <h2>DESIGN PROCESS</h2>
              <div className="spacer-15"></div>
              <img
                src="./img/banuassets/Banu_DesignProcess_UPRAVEN.png"
                alt="galleryimage"
                className="img-responsivefull"
              />
              <div className="spacer-30"></div>
              <h4>Research Approach</h4>
              <p>
                Leveraging Double Diamond and Design Thinking methodologies, I
                addressed challenges faced by women entrepreneurs through an
                iterative, nonlinear process. My key strength lies in navigating
                complex problem spaces and delivering impactful solutions within
                tight deadlines:
              </p>
              <li>Started with an open mind, avoiding predefined solutions</li>
              <li>
                Continuously refined 'How Might We' questions during the
                Emphasize and Define phases
              </li>
              <li>Conducted generative research to uncover genuine problems</li>
              <li>
                Iteratively developed and refined solutions from ideation to
                implementation
              </li>
              <li>
                Used a Kanban board for agile project management and ensured
                timely delivery
              </li>
            </div>
            <div className="spacer-50"></div>

            <table>
              <thead>
                <tr>
                  <th scope="col">
                    <i className="fa fa-user-o"></i> My Role
                  </th>
                  <th scope="col">
                    <i className="fa fa-calendar-o"></i> Duration
                  </th>
                  <th scope="col">
                    <i className="fa fa-wrench"></i> My Tools
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="My Role">
                    As the sole force behind this study, I navigated through the
                    project's lifecycle wearing multiple hats; Project Manager,
                    UX Researcher and UI Designer.
                  </td>
                  <td data-label="Duration">
                    The project was completed over 10 weeks.
                    <br />
                    <a
                      href="https://banuakman.notion.site/UPRAVEN-Project-Plan-b9674eb99f974c6a81cfc3a971a3b93b"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link to my project plan & timeline in Notion
                    </a>
                  </td>
                  <td data-label="My Tools">
                    <b>Project Management:</b> Notion
                    <br />
                    <b>UX Research:</b> Zoom, Otter.ai, Typeform, MAZE
                    <br />
                    <b>Design:</b> Figma, Adobe Illustrator, Photoshop,
                    PremierePro
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row gx-5">
            <div className="col-lg-12">
              <div className="spacer-50"></div>
              <h3>EMPATHIZE</h3>
              <hr className="redline" />
              <div className="spacer-30"></div>
            </div>
          </div>

          <div className="row gx-5">
            <div className="col-lg-12">
              <h4>
                <colored>▶︎ </colored>Desk Research
              </h4>
              <p>
                <img
                  src="./img/banuassets/NirEyal_Hooked.png"
                  alt="galleryimage"
                  className="img-inline-right"
                />
                During the initial phase of my research, I focused on
                emphasizing the distinctive challenges that women entrepreneurs
                face. My aim was to explore ways to foster gender equality and
                empower women in entrepreneurship. A key finding was the
                critical role of education as a form of "human capital," which
                is essential for entrepreneurs to develop skills and succeed in
                their ventures. This led me to investigate effective adult
                learning methods, where I discovered the importance of
                repetition for knowledge retention, familiarity, and confidence
                building.
              </p>
              <p>
                With this insight, I endeavored to craft an app experience that
                encourages daily user engagement. To achieve this, I utilized
                Nir Eyal's Hook Model to create a cycle of engagement that
                promotes habitual use. I wanted to encourage users not to break
                their engagement chain through gamification.
              </p>
              <p>
                After evaluating the mixed reception towards gamification in
                adult learning, which is often perceived as juvenile or
                distracting in serious contexts,I decided to carefully integrate
                gameful elements This approach underscores progress and
                achievement without detracting from the gravity of
                entrepreneurship.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Portfolio;
