import React from "react";

import AOS from "aos";
AOS.init();

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2
              className="aboutme"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              About Me
            </h2>
            <div
              className="space-border"
              data-aos="fade-up"
              data-aos-delay="20"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            ></div>
          </div>
          <div
            className="col-md-8 text-start m-auto"
            data-aos="fade-up"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <p>
              Hello! I'm Banu Akman, a dedicated UX Designer with a passion for
              learning and growth. My expertise in user and market research,
              combined with a Master's in UX Design and focused training in
              coding and web development, enable me to craft solutions that
              resonate with both users and businesses. I specialize in turning
              qualitative feedback into actionable insights, leading to
              meaningful, user-centered design outcomes.
            </p>
            <p>
              With a background in full-stack development and a broad range of
              modern technologies, I approach UX design with agility and a
              forward-thinking mindset. My extensive experience in project
              management and team leadership has refined my skill in merging
              technical feasibility with human-centric design principles. My
              decade-long experience in organization and leading
              cross-functional teams complements my technical skills, allowing
              me to craft narratives that bridge the gap between technical
              feasibility and human-centered design.
            </p>
            <p>
              I am dedicated to accountability, compassion, and teamwork, and I
              strive to establish meaningful connections in every project. My
              goal is to create inclusive and accessible experiences, fueled by
              empathy and understanding. In a collaborative environment, I
              thrive on sharing insights and learning from others.
            </p>
            <p>
              As an ethical AI enthusiast, I am keen on collaborating with
              companies seeking to integrate AI into their products or systems.
              If you're seeking a holistic designer who combines research skills
              with technical and creative expertise, I'm excited to connect and
              contribute to meaningful digital experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
