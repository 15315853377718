import React, { useEffect } from "react";
import Masonry from "react-masonry-component";
import { createGlobalStyle } from "styled-components";
import AOS from "aos";
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery = function () {
  // Initialize AOS on component mount
  useEffect(() => {
    AOS.init({
      once: true, // Whether animation should happen only once - while scrolling down
      mirror: false, // Whether elements should animate out while scrolling past them
    });

    // This function will trigger the refresh method of AOS to ensure that AOS knows about new elements that might have been added to the DOM.
    AOS.refresh();
  }, []);

  /* lightbox1 */
  const [lighbx, setlighbx] = React.useState(false);
  const handleBtnClick = (): void => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose = (): void => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox2 */
  const [lighbx1, setlighbx1] = React.useState(false);
  const handleBtnClick1 = (): void => {
    setlighbx1(!lighbx1);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose1 = (): void => {
    setlighbx1(!lighbx1);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox3 */
  const [lighbx2, setlighbx2] = React.useState(false);
  const handleBtnClick2 = (): void => {
    setlighbx2(!lighbx2);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose2 = (): void => {
    setlighbx2(!lighbx2);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox4 */
  const [lighbx3, setlighbx3] = React.useState(false);
  const handleBtnClick3 = (): void => {
    setlighbx3(!lighbx3);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose3 = (): void => {
    setlighbx3(!lighbx3);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox5 */
  const [lighbx4, setlighbx4] = React.useState(false);
  const handleBtnClick4 = (): void => {
    setlighbx4(!lighbx4);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose4 = (): void => {
    setlighbx4(!lighbx4);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox6 */
  const [lighbx5, setlighbx5] = React.useState(false);
  const handleBtnClick5 = (): void => {
    setlighbx5(!lighbx5);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose5 = (): void => {
    setlighbx5(!lighbx5);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  return (
    <div className="container">
      <GlobalStyles />
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Portfolio</h2>
          <div className="space-border"></div>
          <p>A selection of the work I’ve done in the last few years</p>
        </div>
      </div>
      <Masonry className={"row my-gallery-class"} elementType={"div"}>
        <div
          className="col-lg-4 image-element-class de_modal de_modal"
          onClick={handleBtnClick}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h5 className="d-tag">
                A Mindful Growth Platform
                <br />
                for Women Entrepreneurs
              </h5>
              <h6 className="d-tag">Generative UX Research & Design</h6>
            </div>
            <img src="./img/gallery/1.jpg" alt="gallery" />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick1}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h5 className="d-tag">
                Increasing Engagement with
                <br />
                Public Profiles & Reviews for AUDIBLE
              </h5>
              <h6 className="d-tag">
                Generative & Evaluative UX Research & Design
              </h6>
            </div>
            <img src="./img/gallery/2.jpg" alt="gallery" />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick2}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h5 className="d-tag">
                Enhancing Online Shopping Experience
                <br />
                at local grocery store PRECYCLE
              </h5>
              <h6 className="d-tag">Evaluative UX Research & Design</h6>
            </div>
            <img
              src="./img/banuassets/portfolio/Precycle_gallery.jpg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick3}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h5 className="d-tag">
                Streamlining Outdoor Adventure Planning
                <br />
                with TREKKED
              </h5>
              <h6 className="d-tag">Generative UX Research & Design</h6>
            </div>
            <img src="./img/gallery/4.jpg" alt="gallery" />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick4}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h5 className="d-tag">
                A Digital Walk Through Beacon, NY:
                <br />
                Local History Experience
              </h5>
              <h6 className="d-tag">
                Generative UX Research &<br />
                PWA React App Development
              </h6>
            </div>
            <img
              src="./img/banuassets/portfolio/JewishBeacon_gallery2.jpg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick5}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h5 className="d-tag">
                UX Case Study <br /> Enhancing Online Shopping Experience
              </h5>
            </div>
            <img src="./img/gallery/6.jpg" alt="gallery" />
          </div>
        </div>
      </Masonry>

      {/* lightbox1 */}
      {lighbx && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5 de_project-info">
                <div className="col-lg-12">
                  <div>
                    <img
                      src="./img/single-gallery/upraven2.jpg"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12">
                  <div className="spacer-30"></div>
                  <div className="col-lg-12 item">
                    <h2>1. MOTIVATION & CHALLENGE</h2>
                    <p>
                      My journey as a serial business founder and mentor has
                      given me a unique perspective on the challenges faced by
                      small business owners, especially those owned by
                      underrepresented groups. Working closely with
                      Entrepreneurship Assistance Centers in NY and small
                      business owners across various sectors, I've seen
                      firsthand the vulnerabilities these businesses face,
                      particularly highlighted during the COVID-19 pandemic.
                      Witnessing the struggles and resilience of these
                      entrepreneurs has deepened my passion for supporting these
                      communities. During the MICA UX Design Masters program, I
                      felt compelled to seize the opportunity to create a
                      meaningful solution for women entrepreneurs as my Capstone
                      Project. I utilized my firsthand experience and unwavering
                      commitment to empowering this underserved group by
                      navigating from uncertainty to clarity within ten weeks to
                      fulfill the graduation requirements.
                    </p>
                    <p>
                      During this period, I deeply explored the problem space of
                      women in entrepreneurship, which often helm ventures in
                      sectors known for limited growth potential, navigating a
                      business world where their presence is markedly less
                      dynamic and expansive compared to their male counterparts.
                      This scenario not only illustrates an economic disparity
                      but also a profound confidence gap, deeply ingrained due
                      to societal, psychological, and educational biases.
                    </p>

                    <p>
                      Research shows that confidence is cultivated through a
                      continuous cycle of{" "}
                      <b>
                        acquiring new skills and applying them in real-world
                        scenarios.
                      </b>{" "}
                      This practice not only solidifies one's knowledge base but
                      also significantly enhances self-assurance in one's
                      entrepreneurial capabilities. Empowering a woman with
                      entrepreneurial knowledge is just the beginning of a long
                      journey to business success.
                    </p>
                    <div className="spacer-30"></div>
                    <h4>PROBLEM STATEMENT</h4>
                    <blockquote className="q-small">
                      How might we develop a tailored digital experience that
                      equips women entrepreneurs with essential skills and
                      enhances their ability to retain and apply this knowledge
                      effectively in their businesses?{" "}
                    </blockquote>
                    <div className="spacer-30"></div>
                    <h4>TARGET AUDIENCE</h4>
                    <p>
                      <b>Primary Users: Women Solo Entrepreneurs</b>
                    </p>
                    <p>
                      Ninety percent of women and minority small business o
                      owners operate their businesses alone, without any
                      employees. Often, they become overwhelmed with the daily
                      tasks and responsibilities of running a business, leaving
                      them with little time to acquire the essential skills and
                      knowledge they need to succeed as entrepreneurs.Our core
                      user base comprises forward-thinking solo women
                      entrepreneurs at various stages of their business journey,
                      from the initial ideation phase to mature operations.
                      UPRAVEN provides them with the essential skills,
                      knowledge, and support network needed to navigate the
                      complexities of entrepreneurship effectively. This focus
                      not only taps into an underserved market but also aligns
                      with broader economic empowerment and gender equality
                      goals.
                    </p>
                    <p>
                      <b>
                        Secondary Users: Collaborators in Entrepreneurial
                        Success
                      </b>
                    </p>
                    <p>
                      UPRAVEN is enriched by the expertise and resources of our
                      secondary users, which include business coaches,
                      educators, and government agencies. Their role is pivotal
                      in supporting our primary users through mentorship,
                      education, and policy-making. Through this collaboration,
                      we aim to create a synergistic ecosystem that enables
                      small business owners to thrive.
                    </p>
                  </div>
                </div>
                <div className="spacer-30"></div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-4">
                  <h4>SOLUTION</h4>
                  <p>
                    My solution is an effortless learning experience for busy
                    women entrepreneurs and seamlessly creating deep thinking
                    time to reveal their blind spots to opportunities - short
                    quests for new skills that can be reached anywhere, anytime.
                  </p>
                  <blockquote>
                    UPRAVEN is designed as a mindful learning platform that
                    creates a place for women business owners to work ON their
                    business rather than in it.
                  </blockquote>
                </div>
                <div className="col-lg-8">
                  <video className="pop" controls>
                    <source
                      src="https://banuassets.s3.us-east-2.amazonaws.com/upraven_video.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="spacer-30"></div>
                <div className="col-lg-12 item">
                  <h2>2. DESIGN PROCESS</h2>
                  <div className="spacer-15"></div>

                  <img
                    src="./img/banuassets/Banu_DesignProcess_UPRAVEN.png"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                  <div className="spacer-30"></div>
                  <p>
                    My design research was a nonlinear, iterative process
                    anchored in Double Diamond and Design Thinking
                    methodologies, focused on women in entrepreneurship. I began
                    with an open mind to explore the problem space without a
                    predefined solution. Throughout the Emphasize and Define
                    phases, I repeatedly iterated the 'How Might We' question to
                    identify real problems through generative research.
                    Similarly, the solution was refined multiple times from
                    ideation to implementation. Working with an agile mindset, I
                    visualized and updated my progress on a Kanban board to stay
                    on track and ensure timely delivery for my final Capstone
                    Project presentation.
                  </p>
                  <div className="spacer-15"></div>

                  <table>
                    <thead>
                      <tr>
                        <th scope="col">
                          <i className="fa fa-user-o"></i> My Role
                        </th>
                        <th scope="col">
                          <i className="fa fa-calendar-o"></i> Duration
                        </th>
                        <th scope="col">
                          <i className="fa fa-wrench"></i> My Tools
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="My Role">
                          As the sole force behind this study, I navigated
                          through the project's lifecycle wearing multiple hats;
                          Project Manager, UX Researcher and UI Designer.
                        </td>
                        <td data-label="Duration">
                          The project was completed over 10 weeks.
                          <br />
                          <a
                            href="https://banuakman.notion.site/UPRAVEN-Project-Plan-b9674eb99f974c6a81cfc3a971a3b93b"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link to my project plan & timeline in Notion
                          </a>
                        </td>
                        <td data-label="My Tools">
                          <b>Project Management:</b> Notion
                          <br />
                          <b>UX Research:</b> Zoom, Otter.ai, Typeform, MAZE
                          <br />
                          <b>Design:</b> Figma, Adobe Illustrator, Photoshop,
                          PremierePro
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-50"></div>
                  <h3>EMPATHIZE</h3>
                  <hr className="redline" />
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Desk Research
                  </h4>
                  <p>
                    <img
                      src="./img/banuassets/NirEyal_Hooked.png"
                      alt="galleryimage"
                      className="img-inline-right"
                    />
                    During the initial phase of my research, I focused on
                    emphasizing the distinctive challenges that women
                    entrepreneurs face. My aim was to explore ways to foster
                    gender equality and empower women in entrepreneurship. A key
                    finding was the critical role of education as a form of
                    "human capital," which is essential for entrepreneurs to
                    develop skills and succeed in their ventures. This led me to
                    investigate effective adult learning methods, where I
                    discovered the importance of repetition for knowledge
                    retention, familiarity, and confidence building.
                  </p>
                  <p>
                    With this insight, I endeavored to craft an app experience
                    that encourages daily user engagement. To achieve this, I
                    utilized Nir Eyal's Hook Model to create a cycle of
                    engagement that promotes habitual use. I wanted to encourage
                    users not to break their engagement chain through
                    gamification.
                  </p>
                  <p>
                    After evaluating the mixed reception towards gamification in
                    adult learning, which is often perceived as juvenile or
                    distracting in serious contexts,I decided to carefully
                    integrate gameful elements This approach underscores
                    progress and achievement without detracting from the gravity
                    of entrepreneurship.
                  </p>
                  <div className="spacer-30"></div>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <h4>
                    <colored>▶︎ </colored>Competitive Analysis
                  </h4>
                  <p>
                    I conducted a comprehensive competitive analysis on online
                    educational resources available for business owners. This
                    involved exploring the offerings of various competitors and
                    identifying and analyzing similar services, as well as their
                    strategies and unique features. The main aim of this
                    detailed investigation was to uncover opportunities for
                    innovation and strategic development and identify market
                    gaps.
                  </p>
                  <div className="spacer-15"></div>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">
                          <i className="fa fa-bolt"></i> Identified Problem
                        </th>
                        <th scope="col">
                          <i className="fa fa-rocket"></i> Feature
                          Implementation
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="Identified Problem">
                          <b>Mobile Unfriendly:</b>
                          <br /> Inconvenient mobile use despite responsive
                          design
                        </td>
                        <td data-label="Feature Implementation">
                          <b>Mobile-First Design:</b>
                          <br /> Seamless usability across devices, ensuring
                          accessibility and ease of navigation.
                        </td>
                      </tr>
                      <tr>
                        <td data-label="Identified Problem">
                          <b>Topic Navigation Complexity:</b>
                          <br /> Too many topics covered, overwhelming to choose
                          and navigate through the topics
                        </td>
                        <td data-label="Feature Implementation">
                          <b>Streamlined Topics:</b>
                          <br /> Categorize content into easily navigable
                          sections for swift topic discovery.
                        </td>
                      </tr>
                      <tr>
                        <td data-label="Identified Problem">
                          <b>Save Difficulty:</b>
                          <br />
                          No option to bookmark information.
                        </td>
                        <td data-label="Feature Implementation">
                          <b>Bookmarking Feature:</b>
                          <br />
                          Easily save and organize for personalized content
                          curation
                        </td>
                      </tr>
                      <tr>
                        <td data-label="Identified Problem">
                          <b>Distracting UI:</b>
                          <br />
                          Gamification that detracts from the experience.
                        </td>
                        <td data-label="Feature Implementation">
                          <b>Balanced Design:</b>
                          <br /> Harmonious visual elements for focus.
                        </td>
                      </tr>
                      <tr>
                        <td data-label="Identified Problem">
                          <b>Generic Content:</b>
                          <br />
                          Lacks small business-specific insights.
                        </td>
                        <td data-label="Feature Implementation">
                          <b>Small Business Focus:</b>
                          <br /> Tailored content for niche needs.
                        </td>
                      </tr>
                      <tr>
                        <td data-label="Identified Problem">
                          <b>Lack of Customization:</b>
                          <br />
                          One-size-fits-all approach.
                        </td>
                        <td data-label="Feature Implementation">
                          <b>Personalized Experience:</b>
                          <br />
                          Onboarding quiz and user preferences.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="spacer-30"></div>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <img
                    src="./img/banuassets/UPRAVENmindmap.jpg"
                    alt="galleryimage"
                    className="img-inline-rightbig"
                  />
                  <br />
                  <h4>
                    <colored>▶︎ </colored>Mind Mapping
                  </h4>
                  <p>
                    I used the mind mapping technique to visually organize and
                    expand upon the ideas generated from the research phase.
                    This approach helped me effectively capture all the initial
                    thoughts and concepts, providing a clear understanding of
                    how these ideas were interconnected. I realized that the
                    project had the potential to be extensive, which meant that
                    I had to select the features wisely and establish a
                    structured framework to ensure that everything was completed
                    within the given timeline.
                  </p>
                  <div className="spacer-15"></div>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <h4>
                    <colored>▶︎ </colored>User Interviews
                  </h4>
                  <p>
                    I carried out six remote interviews with women and
                    underrepresented groups of small business owners. I tried to
                    understand their motivation to start their business, how
                    they formed their business plan, where they are struggling,
                    and their trusted educational resources. My participants
                    ranged in age from 25 to 67 years old, had varying levels of
                    business knowledge and experience, and were located across
                    the USA.
                  </p>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/Quote_Upraven_01.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/Quote_Upraven_02.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12">
                  <h4>
                    <colored>▶︎ </colored>Stakeholder Interviews
                  </h4>
                  <p>
                    I interviewed two stakeholders. <br />
                    - An executive from SBA Women's Business Center, which is
                    designed to assist women in starting and growing small
                    businesses <br />- A business mentor from SCORE Association,
                    providing free expertise & practical 'know-how" to small
                    business owners.
                    <br />
                    This helped me further understand small business owners'
                    behaviors, determine constraints, and identify pain points.
                  </p>
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/Quote_Upraven_03.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/Quote_Upraven_04.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>In-Person Focus Group Workshop
                  </h4>
                  <p>
                    I facilitated a focus group workshop in person, engaging
                    with seven female entrepreneurs from various ages, sectors,
                    and backgrounds. This workshop aimed to delve deeper into
                    the attitudes, needs, and behaviors of my target audience.
                    Adopting a playful and interactive method, I encouraged
                    participants to collaborate and co-design directly with me,
                    utilizing visual tools by Holger Nils Pohl to foster an
                    unbiased atmosphere conducive to open discussion.
                    <img
                      src="./img/banuassets/HNPohl_FocusGroup.jpg"
                      alt="galleryimage"
                      className="img-inline-rightbig"
                    />
                  </p>
                  <p>
                    This workshop marked my first venture into conducting a
                    focus group in person, presenting a unique set of dynamics
                    and challenges distinct from those of remote workshops.
                    While remote tools afford considerable convenience in
                    collaborative efforts on a digital whiteboard within a
                    specific timeframe, in-person workshops offer natural human
                    connection. However, maintaining the scheduled timeframe
                    without succumbing to distractions posed a significant
                    challenge. Managing this workshop
                    single-handedly—facilitating discussions, keeping time, and
                    collecting insights—was a formidable task. Nevertheless, I
                    am proud to have executed this workshop with minimal issues.
                    The experience was incredibly enriching, providing me with
                    valuable lessons for future endeavors.
                  </p>
                  <p>
                    The focus group yielded invaluable feedback and insights
                    integral to my research. Despite time constraints preventing
                    us from completing all planned exercises, follow-up calls
                    with select participants allowed me to compile a
                    comprehensive list of feature ideas generated directly by my
                    target users.
                  </p>
                  <ul>
                    <li>
                      Lessons combined with real stories from other sole
                      entrepreneurs for inspiration.
                    </li>
                    <li>
                      Mechanisms for accountability towards achieving set goals.
                    </li>
                    <li>
                      Tools for defining and understanding customer bases.
                    </li>
                    <li>Guidance on setting realistic goals and strategies.</li>
                    <li>
                      A tiered system of mentorship and consultancy, ranging
                      from free to paid services.
                    </li>
                    <li>
                      A "free board of directors" concept for collaborative
                      decision-making.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-30"></div>
                  <h3>DEFINE</h3>
                  <hr className="redline" />
                  <h4>
                    <colored>▶︎ </colored>Insights
                  </h4>
                  <p>
                    Following a thorough generative research phase, I
                    synthesized several critical insights to steer the design
                    process for UPRAVEN. These insights are instrumental in
                    shaping a user-centric platform that addresses the specific
                    needs and challenges of women entrepreneurs:
                  </p>
                  <ol>
                    <li>
                      <b>Time Management Struggles: </b>Women entrepreneurs need
                      brief, impactful learning modules that fit into their busy
                      schedules.
                    </li>
                    <li>
                      <b>Content Clarity and Organization:</b> Clear,
                      well-organized, and streamlined content is crucial to
                      alleviate confusion from existing disorganized resources.
                    </li>
                    <li>
                      <b>Motivation and Engagement:</b> Engaging content and a
                      supportive community are essential to sustain continuous
                      learning interest.
                    </li>
                    <li>
                      <b>Quality of Instruction:</b> Effective learning hinges
                      on the instructor's communication skills, quality
                      materials, and user-friendly technology.
                    </li>
                    <li>
                      <b>Quality of Instruction:</b> Personalized Learning
                      Experience: Tailored resources that align with individual
                      business needs and learning styles are in demand.
                    </li>
                    <li>
                      <b>Networking and Community Support:</b> A platform for
                      peer discussion and collaborative decision-making is
                      desired for enhanced mentorship and support.
                    </li>
                    <li>
                      <b>Practical Tools and Resources:</b> There's a need for
                      tools aiding in goal setting, customer analysis, and
                      strategic planning for business improvement.
                    </li>
                    <li>
                      <b>Integration of Gamification Elements:</b> Integrating
                      gamification thoughtfully can motivate users without
                      undermining the seriousness of learning.
                    </li>
                  </ol>
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>User Personas
                  </h4>
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/UPRAVEN_Persona_Rachel_2.jpg"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                </div>
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/UPRAVEN_Persona_Cheryl_2.jpg"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                </div>
                <div className="spacer-30"></div>
              </div>

              <div className="row g-0 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Story Board
                  </h4>
                </div>

                <div className="row g-0 de_project-info">
                  <div className="col-lg-2">
                    <img
                      src="./img/banuassets/UPRAVEN_Storyboard-01.png"
                      alt="galleryimage"
                      className="img-responsivefull"
                    />
                  </div>
                  <div className="col-lg-2">
                    <img
                      src="./img/banuassets/UPRAVEN_Storyboard-02.png"
                      alt="galleryimage"
                      className="img-responsivefull"
                    />
                  </div>
                  <div className="col-lg-2">
                    <img
                      src="./img/banuassets/UPRAVEN_Storyboard-03.png"
                      alt="galleryimage"
                      className="img-responsivefull"
                    />
                  </div>
                  <div className="col-lg-2">
                    <img
                      src="./img/banuassets/UPRAVEN_Storyboard-04.png"
                      alt="galleryimage"
                      className="img-responsivefull"
                    />
                  </div>
                  <div className="col-lg-2">
                    <img
                      src="./img/banuassets/UPRAVEN_Storyboard-05.png"
                      alt="galleryimage"
                      className="img-responsivefull"
                    />
                  </div>
                  <div className="col-lg-2">
                    <img
                      src="./img/banuassets/UPRAVEN_Storyboard-06.png"
                      alt="galleryimage"
                      className="img-responsivefull"
                    />
                  </div>
                  <div className="spacer-30"></div>
                </div>

                <div className="spacer-30"></div>
              </div>
              <div className="row g-0 de_project-info">
                <div className="col-lg-12">
                  <h3>IDEATE</h3>
                  <hr className="redline" />
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Crazy 8's and Lo-Fi Sketches
                  </h4>
                  <p>
                    Leveraging insights from my initial research, I used the
                    dynamic Crazy 8's brainstorming method to generate creative
                    ideas for a mobile application.
                  </p>
                  <p>
                    Selecting the most promising ideas, I crafted detailed
                    low-fidelity sketches, concentrating on creating an
                    intuitive home screen and an engaging short visual learning
                    journey. Additionally, I mapped out strategies to implement
                    effective triggers and progression feedback to enhance user
                    engagement.
                  </p>
                </div>
              </div>

              <div className="spacer-30"></div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/UPRAVEN_lo-fi_Sketch1.png"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                </div>
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/UPRAVEN_lo-fi_Sketch2.png"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-50"></div>

                  <h3>PROTOTYPE</h3>
                  <hr className="redline" />
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Object Mapping
                  </h4>
                  <p>
                    I adopted the Object-oriented UX (OOUX) methodology to
                    structure the app's internal architecture meticulously. This
                    innovative approach enabled me to distill complex systems
                    into a clear, navigable framework, bridging strategic vision
                    with tangible design.
                  </p>
                  <p>
                    I conceptualized each learning module as an "EPISODE," a
                    building block that, when combined, constructs a
                    comprehensive "QUEST" tailored for the "BUSINESS OWNER."
                    These elements became the foundational "OBJECTS" of my
                    design narrative. My process unfolded as follows:
                  </p>
                  <li>
                    Defining the Core Content: I identified the essence of each
                    object.
                  </li>
                  <li>
                    Metadata Configuration: I detailed metadata for user sorting
                    or filtering.
                  </li>
                  <li>
                    Nested Objects for Cross-Linking: I introduced nested
                    objects to foster interconnected experiences.
                  </li>
                  <li>
                    Forced Ranking: With a discerning eye, I reorganized all
                    items beneath each primary object from most to least
                    important, prioritizing user engagement.
                  </li>
                  <li>
                    CTAs Integration: I placed Calls to Action - primary
                    interactions that resonate with each object
                  </li>
                </div>
                <div>
                  <img
                    src="./img/banuassets/UPRAVEN_ObjectFlow.png"
                    alt="galleryimage"
                    className="img-responsive"
                  />
                </div>
              </div>

              <div className="col-lg-12 item de_project-info">
                <div className="spacer-50"></div>
                <h4>
                  <colored>▶︎ </colored>Mid-Fidelity Prototype
                </h4>
                <p>
                  Progressing from the initial framework established through
                  Object Mapping, I transitioned my UI concepts into a more
                  refined mid-fidelity prototype. During this phase, I focused
                  on adding more functionality and detail to the user interface
                  to create a more intuitive experience. While I did not
                  prioritize visual branding at this point, I crafted a basic
                  interactive prototype that was ready for preliminary user
                  testing. This prototype was designed without any branding
                  distractions, allowing for valuable feedback and iterative
                  improvements.
                </p>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/UPRAVEN_mid-fi_Prototype1.png"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                </div>
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/UPRAVEN_mid-fi_Prototype2.png"
                    alt="galleryimage"
                    className="img-responsivefull"
                  />
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-50"></div>

                  <h3>TEST</h3>
                  <hr className="redline" />
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Diary Study for Validation of External
                    Trigger
                  </h4>
                  <p>
                    Through a Diary Study, I conducted an investigation to
                    explore how a carefully timed and thought-provoking question
                    can significantly impact critical thinking and spark
                    curiosity among women business owners, thereby promoting
                    business mindfulness and strategic decision-making. his
                    investigation was rooted in validating my hypothesis:
                    leveraging the Hook Model, which involved designing a daily
                    notification mechanism that delivers thoughtfully crafted
                    questions as external triggers. These prompts aim to
                    encourage users to engage with Upraven, promoting productive
                    reflection and a desire to explore further. These
                    notifications are designed to act as the app's actuator,
                    cycling users through the hook model, and nurturing a
                    habitual engagement with the platform.
                  </p>
                  <p>
                    For this purpose, three female small business owners were
                    immersed in an experience where they received one question
                    each morning for a week. These inquiries, derived from the
                    "Thinking Time - Business Edition" deck by Best Self,
                    spanned a variety of topics including pride, challenges,
                    goals, customer needs, areas for improvement, work-life
                    balance, and future aspirations.
                  </p>
                  <p>
                    In the exit interviews, all participants provided
                    overwhelmingly positive feedback. They emphasized the
                    significant role of the reflection process, recognizing it
                    as an invaluable mechanism for both personal and
                    professional development. This feedback underscores the
                    potential of thoughtful daily prompts in inspiring mindful
                    engagement and learning, affirming the core hypothesis of my
                    study.
                  </p>
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/Quote_Upraven_05.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-6">
                  <img
                    src="./img/banuassets/Quote_Upraven_06.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-12 item">
                  <div className="spacer-30"></div>
                  <img
                    src="https://banuassets.s3.us-east-2.amazonaws.com/UPRAVEN-MidFiTest_Transparent2.gif"
                    alt="galleryimage"
                    className="img-inline-rightbig"
                  />
                  <h4>
                    <colored>▶︎ </colored>Usability Testing Mid-Fidelity
                    Functions
                  </h4>
                  <p>
                    I completed a usability testing via Mid Fidelity prototype
                    to determine how well the app facilitates user interaction
                    with educational content. My objectives were:{" "}
                    <li>
                      evaluate the ease of navigating and joining Daily Quests
                    </li>
                    <li>
                      the user experience of accessing, watching, and sharing
                      video classes
                    </li>
                    <li>the effectiveness of exploring in-depth subjects</li>
                    <li>
                      the simplicity of managing saved content in the Vault
                    </li>
                  </p>
                  <p>
                    I carried out five guided Usability Tests via Zoom, each
                    lasting about 30 minutes, utilizing mid-fidelity prototypes.
                    The diverse backgrounds of the participants, ranging in age,
                    business knowledge, and experience, were crucial for
                    uncovering unique insights into the app's usability and its
                    alignment with users' preferences and needs.
                  </p>
                  <blockquote className="q-small-clear">
                    <h5>A pivotal insight emerged during our sessions: </h5>

                    <p>
                      Participants strongly preferred audio learning over video.
                      They preferred audio formats for a more accessible and
                      less distracting learning experience.
                    </p>
                  </blockquote>
                  <h5>Highlights of the Experience: </h5>
                  <p>
                    <b>Ease of Navigation: </b>The app's intuitive interface was
                    a hit among users, with unanimous praise for its
                    straightforward navigational structure.
                  </p>
                  <p>
                    <b>Simplified Onboarding: </b>The streamlined introduction,
                    featuring a singular course to start, was lauded for its
                    clarity and effectiveness in easing users into the learning
                    environment.
                  </p>
                  <p>
                    <b>Learning on the Go: </b>The ability to access educational
                    content anytime, anywhere resonated strongly with our users,
                    emphasizing the value of mobility in learning.
                  </p>
                  <h5>Areas for Enhancement: </h5>
                  <p>
                    <b>Iconography Clarity: </b>Users expressed frustrations
                    with the share and save icons, suggesting a need for more
                    intuitive design choices to enhance user interaction.
                  </p>
                  <p>
                    <b>Accessibility of Deep Think Questions:</b>
                    Participants who received triggering notification samples in
                    Dairy Study expressed a desire for these thought-provoking
                    questions to be more readily available within the app,
                    enriching the learning experience.
                  </p>
                </div>
              </div>

              <div className="row g-0 de_project-info">
                <div className="col-lg-12">
                  <div className="spacer-50"></div>
                  <h3>IMPLEMENT</h3>
                  <hr className="redline" />
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Enhanced Literature Analysis
                  </h4>
                  <p>
                    In redefining the auditory learning experience for UPRAVEN,
                    I integrated essential cognitive and design principles
                    tailored for adult learners. The enhancements focus on
                    optimizing engagement and learning outcomes:
                  </p>
                  <p>
                    <b>High-Quality Audio:</b> Ensures clarity and reduces
                    listening fatigue, crucial for maintaining focus and
                    enhancing comprehension.
                  </p>
                  <p>
                    <b>Segmented Content:</b> Brief, focused content pieces
                    allow for flexible, incremental learning, fitting seamlessly
                    into varied schedules.
                  </p>
                  <p>
                    <b>Interactive Engagement:</b> Interactive prompts and
                    questions engage the listener, fostering active
                    participation and deeper learning.
                  </p>
                  <p>
                    <b>User Control:</b>Essential playback features support
                    personalized pace and integration into daily routines.
                  </p>
                  <p>
                    <b>Supplementary Materials:</b>Optional text transcripts and
                    summaries provide visual reinforcement, catering to diverse
                    learning preferences.
                  </p>
                  <div className="spacer-30"></div>
                </div>
              </div>

              <div className="row g-5 de_project-info">
                <div className="col-lg-7">
                  <h4>
                    <colored>▶︎ </colored>Hi-fidelity Prototype
                  </h4>
                  <div className="spacer-15"></div>

                  <h5>Rapid Prototyping for Timely Delivery</h5>
                  <p>
                    With a tight deadline, I needed a streamlined and efficient
                    process to manage complexity and expedite iterations.
                    Leveraging my development background, I focused on reusable
                    components to enhance speed and practicality.
                  </p>
                  <h5>Building a Robust Design System</h5>
                  <p>
                    <b>Efficient Design System Creation: </b>I quickly
                    established a comprehensive design system and created
                    reusable interactive components using Figma variants. This
                    approach ensured rapid iteration, consistency, and
                    efficiency across the design.
                    <br />
                    <b>Semantic and Stakeholder-Friendly Naming: </b>
                    Despite being the sole designer, I named and organized the
                    design system with clear, descriptive logic to ensure it was
                    easily understandable to various stakeholders. Naming a
                    design system typically requires thoughtful collaboration
                    and testing with other team members, but given my solo role,
                    I did my best to create a system that would facilitate
                    smooth future collaboration.
                  </p>
                  <h5>AI-Generated Backgrounds: </h5>
                  <p>
                    To enhance the visual appeal swiftly, I utilized Dall-E 2
                    generative AI to create background images from text prompts.
                    I saw this as a bold opportunity to test generative AI
                    technology in my capstone project, especially since it could
                    handle an experimental approach. Notably, this was in
                    November 2022, before ChatGPT 3.5 was publicly available.
                    Although the results were not quite market-ready, they
                    enabled me to incorporate engaging visuals extremely
                    quickly, significantly accelerating the prototype
                    development process.
                  </p>
                </div>
                <div className="col-lg-5">
                  <div className="spacer-15"></div>

                  <img
                    src="./img/banuassets/UPRAVEN_DesignSystem.jpg"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                  <img
                    src="./img/banuassets/UPRAVEN_Components.jpg"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="row g-0 de_project-info">
                <div className="col-lg-12">
                  <h5>Enhancing User Engagement:</h5>
                  <p>
                    Interactive components were key to boosting usability and
                    learning experience. Gamified elements, such as confetti
                    explosions after each completed quest and animated streaks
                    to promote consistency, added a layer of engagement and
                    motivation.
                  </p>
                  <h5>Rapid Iteration and Testing:</h5>
                  <p>
                    Expediting the high-fidelity prototype enabled ongoing user
                    testing and valuable feedback collection. This efficient
                    approach ensured the prototype was high-quality and
                    delivered on time, ready for final presentation and
                    stakeholder review.
                  </p>
                </div>
              </div>
              <div className="row g-0 de_project-info">
                <div className="col-lg-12">
                  <div className="spacer-50"></div>
                  <h3>EXPERIMENT</h3>
                  <hr className="redline" />
                  <div className="spacer-30"></div>
                  <h4>
                    <colored>▶︎ </colored>Remote Testing
                  </h4>
                </div>
              </div>
              <div className="row g-0 de_project-info">
                <div className="col-lg-5">
                  <p>
                    To validate the high-fidelity prototype, I conducted remote
                    user testing through Maze.co. This ongoing test{" "}
                    <a
                      href="https://t.maze.co/128538240"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      -accessible here-
                    </a>{" "}
                    tasks users with completing three specific actions within
                    the UPRAVEN app:
                    <li>continuing and sharing their Daily Quest</li>
                    <li>listening to and bookmarking their quest</li>
                    <li>reading and bookmarking their quest</li>
                  </p>
                  <p>
                    The test aims to assess usability and user interaction
                    efficiency, with current results indicating a 70% direct
                    success rate across 10 testers and an average interface
                    experience rating of 8.6 out of 10.
                  </p>
                  <p>
                    Feedback from users highlighted several areas for
                    improvement:
                    <li>
                      Reduce clutter for a cleaner, more intuitive experience.
                    </li>
                    <li>
                      Improve the visibility and accessibility of the bookmark
                      button.
                    </li>
                    <li>Optimize the back navigation to be more intuitive.</li>
                    <li>
                      Ensure interactive elements like buttons manifest quickly
                      to enhance user experience.
                    </li>
                  </p>
                  <p>
                    Overall, users enjoyed having the option to choose between
                    reading and listening, the elegant design, and the
                    interactive elements that enhance engagement. Heatmaps and
                    collected data provide valuable insights into user behavior
                    and areas for further improvement, guiding the next
                    iteration of the prototype.
                  </p>
                  <div className="spacer-30"></div>
                </div>
                <div className="col-lg-7">
                  <img
                    src="./img/banuassets/UPRAVEN_MazeTesting.png"
                    alt="galleryimage"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="row g-5 de_project-info">
                <div className="col-lg-12">
                  <div className="spacer-30"></div>
                  <div className="col-lg-12 item">
                    <h2>3. REFLECTION</h2>
                    <p>
                      Getting feedback early and often is crucial. Testing at
                      low fidelity is particularly valuable for understanding
                      information architecture and feature prioritization.
                      Iterating more frequently, especially early in the
                      project, can prevent locking into a design layout that
                      might not work.
                    </p>
                    <p>
                      This project helped me shift my perspective on time from a
                      constraint to an allowance. Working with tight deadlines
                      fostered my creativity and innovation, allowing me to
                      focus on the design process, prevent scope creep, and
                      prioritize tasks effectively. The time constraints pushed
                      me to think outside the box and devise creative solutions
                      to problems.
                    </p>
                    <p>
                      It was incredibly rewarding to dive deeper into the
                      problem space of small business owners and create a
                      solution tailored to their needs. This experience fueled
                      my excitement and passion for working in this area,
                      reinforcing my commitment to supporting women and minority
                      entrepreneurs.
                    </p>
                  </div>
                </div>
                <div className="spacer-30"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox2 */}
      {lighbx1 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose1}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-12 de_project-info">
                  <div className="col-lg-12 item">
                    <img
                      src="./img/single-gallery/audible1.jpg"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="spacer-30"></div>
                  <h2>Research Goals & Objectives</h2>
                  <p>
                    Audible is an online audiobook and podcast service owned by
                    Amazon.com Inc. that allows users to purchase and stream
                    audiobooks and other forms of similar audio content. This
                    research aims to identify and understand
                    <li>how Audible users influenced what listen to next</li>
                    <li>
                      how Audible users share their feelings and reviews about
                      the audiobooks
                    </li>
                    This research will support Audible in developing an
                    inclusive engagement experience for its users in building
                    relationships with other listeners, authors, and narrators.
                  </p>
                  <div className="spacer-30"></div>
                </div>
              </div>

              <div className="row g-5">
                <div className="col-lg-3">
                  <div className="row g-4">
                    <img
                      src="https://banuassets.s3.us-east-2.amazonaws.com/AudibleUpdatedPrototype.gif"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="col-lg-9 de_project-info">
                  <h3>PROBLEM STATEMENT</h3>
                  <blockquote className="q-small">
                    How might we increase engagement through public profiles and
                    reviews for Audible users?
                  </blockquote>
                  <div className="spacer-50"></div>
                  <h3>more details will be added here soon...</h3>
                  <h4>Deliverables:</h4>
                  <p>
                    <li>Competitive Analysis</li>
                    <li>User Personas</li>
                    <li>Experince Mapping</li>
                    <li>Lightning Demo Sketches</li>
                    <li>Lo-Fidelity Prototype for Testing</li>
                    <li>Hi-Fidelity Prototype for Testing</li>
                    <li>Usability Testing with Low & Hi Fidelity Prototypes</li>
                  </p>
                  <div className="spacer-30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox3 */}
      {lighbx2 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose2}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-12 de_project-info">
                  <div className="col-lg-12 item">
                    <img
                      src="./img/single-gallery/btc1.jpg"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="spacer-30"></div>
                  <h2>LOREM IPSUM</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent ac imperdiet lectus. Ut egestas mauris ac turpis
                    egestas efficitur. In hendrerit eros sed erat tempus
                    aliquet. Nam auctor lectus sed purus placerat fringilla.
                    Nullam mi nibh, placerat eu lacinia elementum, feugiat eu
                    nunc. Suspendisse vitae gravida libero, eu aliquam augue.
                    Sed quam neque, fringilla id velit id, lacinia viverra
                    magna. Curabitur dolor eros, volutpat quis posuere eget,
                    sodales ac ex. Curabitur pulvinar nisi sed sagittis commodo.
                  </p>
                  <h3>PROBLEM STATEMENT</h3>
                  <blockquote>
                    "Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit..."
                  </blockquote>
                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>MY ROLE:
                      <span>
                        Project Manager, UX Researcher, UX/UI Designer
                      </span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>DURATION
                      <span>
                        10 Weeks - emphasizing user research and usability
                        testing
                      </span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-wrench"></i>My Tools:{" "}
                      <span>
                        Design: Figma, Adobe Illustrator, Adobe Photoshop, Adobe
                        Premiere{" "}
                      </span>
                      <span>Project Management:Notion</span>
                      <span>UX Research:Zoom, MAZE, Typeform, Otter.ai</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://www.banuakman.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.banuakman.com
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  <p>
                    Duis ad adipisicing id id in duis nisi qui veniam esse
                    voluptate cillum pariatur cupidatat ut dolore amet aliquip
                    cillum ad minim cillum nulla consectetur dolor culpa
                    deserunt mollit dolor ea pariatur laboris sed tempor laboris
                    quis commodo. Ut duis cupidatat consectetur cillum veniam eu
                    quis minim irure fugiat ut consequat do veniam duis dolor
                    quis cupidatat deserunt ut qui minim id dolor nisi sed non
                    ut proident enim culpa dolor elit dolor aliquip dolore enim
                    excepteur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* lightbox4 */}
      {lighbx3 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose3}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-12 de_project-info">
                  <div className="col-lg-12 item">
                    <img
                      src="./img/single-gallery/trekked1.jpg"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="spacer-30"></div>
                  <h2>Research Goals & Objectives</h2>
                  <p>
                    Audible is an online audiobook and podcast service owned by
                    Amazon.com Inc. that allows users to purchase and stream
                    audiobooks and other forms of similar audio content. This
                    research aims to identify and understand
                    <li>how Audible users influenced what listen to next</li>
                    <li>
                      how Audible users share their feelings and reviews about
                      the audiobooks
                    </li>
                    This research will support Audible in developing an
                    inclusive engagement experience for its users in building
                    relationships with other listeners, authors, and narrators.
                  </p>
                  <div className="spacer-30"></div>
                </div>
              </div>

              <div className="row g-5">
                <div className="col-lg-3">
                  <div className="row g-4">
                    <img
                      src="https://banuassets.s3.us-east-2.amazonaws.com/AudibleUpdatedPrototype.gif"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="col-lg-9 de_project-info">
                  <h3>PROBLEM STATEMENT</h3>
                  <blockquote className="q-small">
                    How might we increase engagement through public profiles and
                    reviews for Audible users?
                  </blockquote>
                  <div className="spacer-50"></div>
                  <h3>more details will be added here soon...</h3>
                  <h4>Deliverables:</h4>
                  <p>
                    <li>Competitive Analysis</li>
                    <li>User Personas</li>
                    <li>Experince Mapping</li>
                    <li>Lightning Demo Sketches</li>
                    <li>Lo-Fidelity Prototype for Testing</li>
                    <li>Hi-Fidelity Prototype for Testing</li>
                    <li>Usability Testing with Low & Hi Fidelity Prototypes</li>
                  </p>
                  <div className="spacer-30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox5 */}
      {lighbx4 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose4}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-12 de_project-info">
                  <div className="col-lg-12 item">
                    <img
                      src="./img/single-gallery/jewishbeaconhistory1.jpg"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="spacer-50"></div>
                  <h4>Overview</h4>
                  <p>
                    The Jewish Beacon History Walking Tour App is an extension
                    of a local history discovery research and exhibition
                    enriched with a walking tour experience - led by{" "}
                    <a
                      aria-label="Anna Marcus - link opens in a new tab"
                      href="https://anchoryourlegacy.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      Anna Marcus
                    </a>
                    , a dedicated historian and storyteller. This project, in
                    collaboration with the Beacon Historical Society and Beacon
                    Hebrew Alliance, celebrates the centennial of Beacon's first
                    synagogue and uncovers the rich tapestry of Jewish
                    enterprise in the city with. As an immigrant from Turkey, I
                    found it fascinating to explore the interwoven stories of
                    early Jewish families and their contributions to Beacon's
                    development. Serving as the sole designer and primary
                    developer, I created an accessible and user-friendly app
                    that enriches the walking tour experience, blending
                    historical storytelling with interactive technology to
                    engage both avid historians and casual visitors.
                  </p>
                  <h5>
                    <a
                      aria-label="Jewish Beacon Historical
                    Walking Tour App - link opens in a new tab"
                      href="https://www.jewishbeaconhistory.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      www.jewishbeaconhistory.com/
                    </a>
                  </h5>
                </div>
                <div className="col-lg-9 de_project-info">
                  <h4>Challenges</h4>
                  <p>
                    The primary challenge for the Jewish Beacon Historical
                    Walking Tour App was to create a captivating and
                    user-friendly digital platform that seamlessly integrated
                    extensive historical visuals with an interactive map feature
                    - ensuring future-proof technology and adaptability across
                    multiple platforms. Our goal was to craft an immersive
                    journey appealing to both avid historians and casual
                    visitors seeking a glimpse into Beacon's past.
                  </p>
                  <p>
                    A significant consideration was given to presenting
                    businesses that once thrived but no longer existed, ensuring
                    that users could visualize and appreciate the historical
                    context of Jewish Businesses on Beacon's Main Street. The
                    project aimed to not just showcase historical facts but to
                    evoke a sense of connection and imagination, allowing users
                    to traverse through time and witness the evolution of their
                    surroundings.
                  </p>
                  <div className="spacer-30"></div>
                  <h4>Design Approach</h4>
                  <p>
                    Given the constraints of time and resources, the project was
                    volunteerly run over a span of just four weeks as a side
                    project- prioritizing a timely and low-cost launch.{" "}
                  </p>
                  <p>
                    To start, I conducted a brief <b>competitor analysis</b> to
                    decide which features we could include in the app. I also
                    checked user pain points and expectations through Reddit and
                    app store reviews to better understand what potential users
                    might want from the app. This groundwork allowed us to focus
                    on the most impactful features and user needs.
                  </p>
                  <p>
                    Adopting a user-centric design approach, I began with
                    comprehensive <b>user interviews</b> to validate the
                    audience's desires and preferences. The project evolved
                    through <b>iterative prototype testing</b>, ensuring the app
                    was both intuitive and rich in content.
                  </p>
                </div>
                <div className="col-lg-3 de_project-info">
                  <div className="spacer-15"></div>
                  <div className="row g-4">
                    <img
                      src="https://banuassets.s3.us-east-2.amazonaws.com/JewishBeaconWalkingTour.gif"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-12 de_project-info">
                  <h4>Development and Technologies</h4>
                  <p>
                    I leveraged <b>React JS</b>'s robust capabilities to develop
                    this project as a <b>Progressive Web App (PWA)</b>, ensuring
                    cross-platform functionality and easy access. <b>Leaflet</b>{" "}
                    was utilized to map the town, and the app was enhanced with
                    a collection of React libraries, including{" "}
                    <b>React Helmet</b> for managing the document head,{" "}
                    <b>React Player</b> for multimedia content integration, and{" "}
                    <b>React Bootstrap</b> for responsive design.
                  </p>
                  <p>
                    During the development process, we had a tight deadline to
                    launch the app in conjunction with an upcoming exhibition,
                    which required us to work at a fast pace. To ensure that we
                    met the deadline, I collaborated with another developer,{" "}
                    <a
                      aria-label="Sefa Gundogdu - link opens in a new tab"
                      href="https://www.linkedin.com/in/sefa-g%C3%BCndo%C4%9Fdu-223a51204/"
                      target="_blank"
                      rel="noopener"
                    >
                      Sefa Gundogdu,
                    </a>{" "}
                    using <b>GitHub</b> and <b>Zoom</b> for smooth communication
                    and coordination. Despite the constraints, our collective
                    volunteer commitment ensured the successful and timely
                    launch of the app. This project allowed me to demonstrate my
                    ability to learn and utilize new technologies in coding and
                    my passion for contributing to a meaningful exploration of
                    my town's history.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox6 */}
      {lighbx5 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose5}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-12 de_project-info">
                  <div className="col-lg-12 item">
                    <img
                      src="./img/single-gallery/upraven1.jpg"
                      alt="galleryimage"
                      className="img-fluid"
                    />
                  </div>
                  <div className="spacer-30"></div>
                  <h2>LOREM IPSUM</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent ac imperdiet lectus. Ut egestas mauris ac turpis
                    egestas efficitur. In hendrerit eros sed erat tempus
                    aliquet. Nam auctor lectus sed purus placerat fringilla.
                    Nullam mi nibh, placerat eu lacinia elementum, feugiat eu
                    nunc. Suspendisse vitae gravida libero, eu aliquam augue.
                    Sed quam neque, fringilla id velit id, lacinia viverra
                    magna. Curabitur dolor eros, volutpat quis posuere eget,
                    sodales ac ex. Curabitur pulvinar nisi sed sagittis commodo.
                  </p>
                  <h3>PROBLEM STATEMENT</h3>
                  <blockquote>
                    "Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit..."
                  </blockquote>
                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>MY ROLE:
                      <span>
                        Project Manager, UX Researcher, UX/UI Designer
                      </span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>DURATION
                      <span>
                        10 Weeks - emphasizing user research and usability
                        testing
                      </span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-wrench"></i>My Tools:{" "}
                      <span>
                        Design: Figma, Adobe Illustrator, Adobe Photoshop, Adobe
                        Premiere{" "}
                      </span>
                      <span>Project Management:Notion</span>
                      <span>UX Research:Zoom, MAZE, Typeform, Otter.ai</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://www.banuakman.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.banuakman.com
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  <p>
                    Duis ad adipisicing id id in duis nisi qui veniam esse
                    voluptate cillum pariatur cupidatat ut dolore amet aliquip
                    cillum ad minim cillum nulla consectetur dolor culpa
                    deserunt mollit dolor ea pariatur laboris sed tempor laboris
                    quis commodo. Ut duis cupidatat consectetur cillum veniam eu
                    quis minim irure fugiat ut consequat do veniam duis dolor
                    quis cupidatat deserunt ut qui minim id dolor nisi sed non
                    ut proident enim culpa dolor elit dolor aliquip dolore enim
                    excepteur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
