import React, { useEffect } from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from "../layout/Navbar";
import Hero from "../section/hero";
import About from "../section/about";
import Blockquote from "../section/blockquote";
import Toolbox from "../section/toolbox";
import Gallery from "../section/gallery";
import Resume from "../section/resume";
import Contact from "../section/contact";
import Footer from "../section/footer";
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { GlobalStyles } from "./style/banustyle";
import Particle from "../layout/Particle";
import { useLocation } from "react-router-dom";
import AOS from "aos";

const image1 = "./img/banuassets/BanuAkmanHero.jpg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home() {
  const query = useQuery();

  useEffect(() => {
    const scrollTo = query.get("scrollTo");
    if (scrollTo) {
      requestAnimationFrame(() => {
        const element = document.getElementById(scrollTo);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });

          // A small timeout to ensure scrolling finishes before refreshing AOS
          setTimeout(() => {
            AOS.refresh();
          }, 600); // Adjust timing as necessary based on scroll duration
        }
      });
    }
  }, [query]);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      once: true, // Whether animation should happen only once - while scrolling down
      mirror: false, // Whether elements should animate out while scrolling past them
    });
  }, []);

  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HERO */}
        <section id="hero-area" className="bg-bottom py-0">
          <Parallax bgImage={image1} strength={300}>
            <Hero />
            <Particle />
            <Link smooth spy to="gallery">
              <span className="mouse transition" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        {/* Gallery */}
        <section id="gallery" className="pb-0">
          <Gallery />
        </section>

        {/* ABOUT */}
        <section id="about" className="py-0">
          <About />
        </section>

        {/* ToolBox */}
        <section id="toolbox">
          <Toolbox />
          <Blockquote />
        </section>

        {/* Resume */}
        <section id="resume" className="pb-0">
          <Resume />
        </section>

        {/* contact */}
        <section id="contact" className="pb-0">
          <Contact />
          <Footer />
        </section>

        <div className="float-text">
          <div className="de_social-icons">
            <span className="buton">
              <a
                href="https://www.linkedin.com/in/banuakman/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin fa-lg"></i>
              </a>
            </span>
            <span className="buton">
              <a
                href="https://github.com/banuakman"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-github fa-lg"></i>
              </a>
            </span>
            <span className="buton">
              <a
                href="https://www.instagram.com/banushka/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram fa-lg"></i>
              </a>
            </span>
          </div>
          <span>Let's connect!</span>
        </div>
      </div>
      <ScrollToTopBtn />
    </>
  );
}

export default Home;
