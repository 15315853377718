import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Homebanu from "../src/pages/homeBanu";
import Upraven from "../src/pages/upraven";
import Portfolio from "../src/pages/portfolio";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Homebanu} />
          <Route exact path="/upraven" component={Upraven} />
          <Route exact path="/capstone" component={Upraven} />
          <Route exact path="/portfolio" component={Portfolio} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
